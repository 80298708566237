<mc-standings-moderation
	(standingsConfigChanged)="standingsConfig$.next($event)"
	(resultsConfigChanged)="resultsConfig$.next($event)"
></mc-standings-moderation>

<mc-race-ranking-standings
	[race]="race$ | async"
	[resultsConfig]="resultsConfig$ | async"
	[standingsConfig]="standingsConfig$ | async"
	[standings]="(standings$ | async) || []"
></mc-race-ranking-standings>

<!--<table border>-->
	<!--<tbody>-->
		<!--<tr *ngFor="let result of results$ | async">-->
			<!--<td>{{ result.driverId }}</td>-->
			<!--<td>{{ result.createdAt }}</td>-->
			<!--<td>{{ result.microtime | laptimeFilter:'minutes' }}</td>-->
		<!--</tr>-->
	<!--</tbody>-->
<!--</table>-->
