import { Pipe, PipeTransform } from '@angular/core';
import { Standing } from '../standing';
import { StandingsConfig } from './standings-config';

@Pipe({
  name: 'standingsFilter'
})
export class StandingsFilterPipe implements PipeTransform {
  transform(standings: Standing[], standingsConfig: StandingsConfig): Standing[] {
    return standings.filter(standing =>
      (standingsConfig.category === undefined || standing.driver.category === standingsConfig.category)
      && (standingsConfig.group === undefined || standing.driver.group === standingsConfig.group)
      && (standingsConfig.spec === undefined || standing.driver.spec === standingsConfig.spec)
      && (standingsConfig.stock === undefined || standing.driver.stock === standingsConfig.stock)
      && (standingsConfig.street === undefined || standing.driver.street === standingsConfig.street)
      && (standingsConfig.women === undefined || standing.driver.women === standingsConfig.women)
      && (standingsConfig.extreme === undefined || standing.driver.extreme === standingsConfig.extreme)
      && (standingsConfig.yt === undefined || standing.driver.yt === standingsConfig.yt)
    );
  }
}
