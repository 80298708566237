import { Injectable } from '@angular/core';
import { CsvParserService } from '../csv-parser.service';
import { GateReading } from './gate-reading';
import {
  distinctUntilChanged,
  filter,
  flatMap,
  groupBy,
  map, pairwise,
  scan,
  tap
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MocksService } from '../mocks/mocks.service';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { StandingsConfig } from '../../ranking/standings/standings-config';
import { Result } from '../../ranking/results/result/result';
import { ResultsService } from '../../ranking/results/results.service';
import { ResultsFilterPipe } from '../../ranking/results/results-config/results-filter.pipe';
import { Driver } from '../drivers/driver';
import { NormalizeTimestampPipe } from '../../app-commons/pipes/normalize-timestamp.pipe';

@Injectable({
  providedIn: 'root'
})
export class GateReadingsService {

  gateReadings: GateReading[] = [];

  constructor(
    private csvParserService: CsvParserService,
    private mocks: MocksService,
    private resultsFilter: ResultsFilterPipe,
    private normalizeTimestampPipe: NormalizeTimestampPipe
  ) {
  }

  static merge(a: GateReading[], b: GateReading[]): GateReading[] {
    return GateReadingsService.findDuplicates(GateReadingsService.concat(a, b))[0];
  }

  static concat(...gateReadingsCollections: GateReading[][]): GateReading[] {
    let merged: GateReading[] = [];
    gateReadingsCollections.forEach((gateReadings: GateReading[]) => {
      merged = merged.concat(gateReadings);
    });
    return GateReadingsService.sort(merged);
  }

  static sort(gateReadings: GateReading[]): GateReading[] {
    return gateReadings.sort((a, b) => {
      if (a.first_detection_date > b.first_detection_date) {
        return 1;
      } else if (a.first_detection_date < b.first_detection_date) {
        return -1;
      } else {
        return a.gr_id - b.gr_id;
      }
    });
  }

  static findDuplicates(gateReadings: GateReading[]): [GateReading[], GateReading[]] {
    const uniques = {};
    const duplicates = [];
    gateReadings.forEach(gateReading => {
      if (gateReading.gr_id in uniques) {
        duplicates.push(gateReading);
      } else {
        uniques[gateReading.gr_id] = gateReading;
      }
    });
    return [
      Object.values(uniques),
      duplicates
    ];
  }

  filterThresholdOperator(stream: Observable<[GateReading, number]>): Observable<GateReading> {
    let threshold;
    return stream.pipe(
      map(([gateReading, thresholdSeconds]: [GateReading, number]) => {
        threshold = (thresholdSeconds || 2) * 1000 * 1000;
        return gateReading;
      }),
      groupBy((gateReading: GateReading) => gateReading.tag_code),
      flatMap(group => group.pipe(
        scan((previousLapStart: GateReading, gateReading: GateReading) => {
          const diff = (new Date(gateReading.first_detection_date).getTime() - new Date(previousLapStart.first_detection_date).getTime()) * 1000;
          if (diff >= threshold) {
            return gateReading;
          } else {
            return previousLapStart;
          }
        }),
        distinctUntilChanged((a, b) => a.gr_id === b.gr_id)
      ))
    );
  }

  filterLaptimeDurationOperator(stream: Observable<[GateReading, number, number]>): Observable<GateReading> {
    let minimumLapDuration;
    let maximumLapDuration;
    return stream.pipe(
      map(([gateReading, minimumLapSeconds, maximumLapSeconds]: [GateReading, number, number]) => {
        minimumLapDuration = (minimumLapSeconds || 60) * 1000 * 1000;
        maximumLapDuration = (maximumLapSeconds || 360) * 1000 * 1000;
        return gateReading;
      }),
      groupBy((gateReading: GateReading) => gateReading.tag_code),
      flatMap(group => group.pipe(
        scan((lastLapStart: GateReading, gateReading: GateReading) => {
          const diff = (new Date(gateReading.first_detection_date).getTime() - new Date(lastLapStart.first_detection_date).getTime()) * 1000;
          if (diff >= minimumLapDuration && diff <= maximumLapDuration) {
            return gateReading;
          } else {
            return lastLapStart;
          }
        }),
        distinctUntilChanged((a, b) => a.gr_id === b.gr_id)
      ))
    );
  }

  mapToResultsOperator(stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>): Observable<Result> {
    let standingsConfig: StandingsConfig;
    let resultsConfig: ResultsConfig;
    let drivers: Driver[] = [];
    return stream.pipe(
      map(([gateReading, newDrivers, newStandingsConfig, newResultsConfig]: [GateReading, Driver[], StandingsConfig, ResultsConfig]) => {
        standingsConfig = newStandingsConfig;
        resultsConfig = newResultsConfig;
        drivers = newDrivers;
        return gateReading;
      }),
      groupBy((gateReading: GateReading) => gateReading.tag_code),
      flatMap(group => group.pipe(
        pairwise(),
        map(([start, finish]: [GateReading, GateReading]) => {
          const driver = drivers.find(driver => driver.epc === finish.tag_code);
          if (driver) {
            const diff = (new Date(finish.first_detection_date).getTime() - new Date(start.first_detection_date).getTime()) * 1000;
            return {
              _id: ResultsService.toRemoteId(+finish.gr_id),
              id: +finish.gr_id,
              driverId: driver.startNumber,
              microtime: diff,
              createdAt: this.normalizeTimestampPipe.transform(finish.first_detection_date),
              correction: 0,
              verification: null
            } as Result;
          } else {
            return null;
          }
        }),
        filter((result: Result) => result !== null && this.resultsFilter.transform([result], resultsConfig).length > 0),
        distinctUntilChanged((a, b) => a.id === b.id)
      ))
    );
  }

  requestGateReadings(mockPath: string): Observable<GateReading[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parse(csvContent))
    );
  }

  parse(csvContent: string): GateReading[] {
    return this.csvParserService.parse(csvContent).map(row => ({
        gr_id: +row[0],
        gate_id: +row[1],
        tag_code: row[2].replace(/\s+/, ''),
        first_detection_date: this.normalizeTimestampPipe.transform(row[3]),
        last_detection_date: this.normalizeTimestampPipe.transform(row[4]),
        save_date: this.normalizeTimestampPipe.transform((row[5])),
        status: +row[6],
        lap: row[7] === 'NULL' ? null : +row[7]
      } as GateReading)
    );
  }
}
