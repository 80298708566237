import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, Renderer2,
  SimpleChanges
} from '@angular/core';
import { ResultsConfig } from '../../results/results-config/results-config';
import { Result } from '../../results/result/result';
import { SeasonPointsPipe } from '../../../seasons/season-points.pipe';
import { Standing } from '../../standing';
import { ResultsFilterPipe } from '../../results/results-config/results-filter.pipe';
import { DriversService } from '../../../gepard/drivers/drivers.service';
import { LaptimeRangeFilterPipe } from '../../results/result/laptime-range-filter.pipe';
import { Race } from '../../races/race';
import { RankingsService } from '../../rankings.service';
import { RemoteResultsService } from '../../results/remote-results.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mc-race-ranking-standings-list',
  templateUrl: './race-ranking-standings-list.component.html',
  styleUrls: ['./race-ranking-standings-list.component.scss']
})
export class RaceRankingStandingsListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() race: Race;
  @Input() standings: Standing[] = [];
  @Input() resultsConfig: ResultsConfig = {};

  @Output() refresh = new EventEmitter<Result>();
  @Output() save = new EventEmitter<Result>();
  @Output() remove = new EventEmitter<Result>();
  @Output() restore = new EventEmitter<Result>();
  @Output() reject = new EventEmitter<Result>();
  @Output() updated = new EventEmitter<Standing[]>();

  rowDetailsOpen = {};
  isModerable = false;

  constructor(
    private driversService: DriversService,
    private resultsFilter: ResultsFilterPipe,
    private laptimeRangeFilterPipe: LaptimeRangeFilterPipe,
    private seasonPointsFilterPipe: SeasonPointsPipe,
    private remoteResultsService: RemoteResultsService
  ) {
  }

  ngOnInit() {
    this.isModerable = !!environment.isAdmin && !!this.race.driversSpreadsheet;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.standings && changes.standings.currentValue) {
      // console.log('standings changed - recalculate order');
      this.recalculateOrder();
    }
  }

  recalculateOrder() {
    this.standings.sort((a, b) => {
      if (+a.rankingResultsAverage === 0) {
        return 1;
      } else if (+b.rankingResultsAverage === 0) {
        return -1;
      } else {
        return a.rankingResultsAverage - b.rankingResultsAverage;
      }
    });
    this.standings.forEach((standing, i) => {
      standing.position = i + 1;
      standing.seasonPoints = this.seasonPointsFilterPipe.transform(standing.position, this.race.pointsMultiplier);
    });
  }

  trackResult(index: number, result: Result) {
    return result.createdAt;
  }

  trackStanding(index: number, standing: Standing) {
    return standing.position;
  }

  isActionUsed(action: string) {
    return this[action].observers.length > 0;
  }

  toggleVerification(result) {
    if (result.verification === null) {
      this.remoteResultsService.reject(result);
      this.reject.emit(result);
      this.save.emit(result);
    } else if (result.verification === false) {
      this.restore.emit(result);
      this.remoteResultsService.restore(result);
      this.save.emit(result);
    } else if (result.verification === true) {
      this.remoteResultsService.unverify(result);
      this.save.emit(result);
    }
    // this.unhover();
    // this.changed.emit(this.result);
  }

  ngOnDestroy() {
    // console.log('destroy standings');
  }
}
