<form novalidate>
	<label>
		<input type="checkbox" (change)="showCsvExport = $event.target.checked" />
		{{drivers.length}} drivers.
	</label>

	<div *ngIf="showCsvExport">
		<hr />
		<div>
			<b>Start number</b>,
			<b>Name</b>,
			<b>EPC</b>,
			<b>Category</b>,
			<b>Spec</b>,
			<b>Stock</b>,
			<b>Women</b>
		</div>
		<div *ngFor="let driver of drivers">
			{{driver.startNumber}},
			{{driver.name}},
			{{driver.epc}},
			{{driver.category}},
			{{driver.spec}},
			{{driver.stock}},
			{{driver.women}}
		</div>
	</div>
</form>
